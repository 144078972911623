import React, {useEffect} from "react";
import axios from "axios";
import {url} from "../config";


import s from './page.module.css'
import Category from "./../components/category/Category";
import PickStreet from "../components/pickStreet/PickStreet";
import {useProductsStore} from "../store/products/products";
import { SetCity} from "../store/geo/geoAPI";
import {useUserStore} from "../store/user/user";
import {useRestaurantStore} from "../store/restaurant/restaurant";
import {useOrderStore} from "../store/order/order";
import GeoInfo from "../components/geoinfo/GeoInfo";

import Footer from "../components/footer/Footer";
import CartToast from "../components/cartToast/CartToast";
import {useAppStore} from "../store/app/app";
import Cart from "../components/cart/Cart";
import ActiveOrdersPopup from "../components/activeOrders/ActiveOrdersPopup";
import {ActiveOrders} from "../components/activeOrders/ActiveOrders";
import {EventSourcing} from "../store/order/EventSource";
import Phone from "../components/phoneNumber/Phone";
import LoginComponent from "../components/login/Login";

import social from './social.png'
import nophone from './nophone.png'



export default function Main() {
    EventSourcing()

    const {products, isFetchedProducts, GetProductsAPI} = useProductsStore()
    const {GetUserInfoAPI, isOpenLoginPopup, setIsOpenLoginPopup} = useUserStore()
    const {haveActiveOrders,
        isOpenPopupActiveOrders, setIsOpenPopupActiveOrders,
        getOrderAPI, setHaveActiveOrders, setActiveOrdersForAPI
    } = useOrderStore()

    const {isCartPopupOpen, setCartPopupOpen, cartToast} = useAppStore()

    const { isPickStreetPopupOpen, setIsPickStreetPopupOpen, getRestaurantInfoAPI} = useRestaurantStore()
    useEffect(() => {
        SetCity()
        getRestaurantInfoAPI()
        getOrderAPI()
        GetProductsAPI()
        GetUserInfoAPI()
        CheckActiveOrdersAPI()

        const handleVisibilityChange = () => {
            if (document.visibilityState === 'visible') {
                SetCity()
                getRestaurantInfoAPI()
                getOrderAPI()
                GetProductsAPI()
                GetUserInfoAPI()
                CheckActiveOrdersAPI()
            }
        };
        document.addEventListener('visibilitychange', handleVisibilityChange);
        return () => {
            document.removeEventListener('visibilitychange', handleVisibilityChange);
        };
    }, [])

    async function CheckActiveOrdersAPI (){
        await axios.get(`${url}/api/v1/orders/checkActiveOrders`).then(function (response) {
            if (response.data.status) {
                if (!response.data.data.haveActiveOrders) {
                    setHaveActiveOrders(false)
                    setActiveOrdersForAPI([])
                } else {
                    setHaveActiveOrders(true)
                    setActiveOrdersForAPI(response.data.data.orders)
                }
            }
        }).catch(function (error) {
            setHaveActiveOrders(false)
            setActiveOrdersForAPI([])
        })
    }




    return (
        <>
            <section className={s.infoWrapper}>
                {/*<Phone />*/}
                <GeoInfo/>

                <div className={s.actions}>
                    <img src={social} alt=""/>
                    <img src={nophone} alt=""/>
                </div>

                {haveActiveOrders ? <ActiveOrders /> : ''}

            </section>



            <main style={{minHeight: '70vh'}}>
                {
                    isFetchedProducts ?
                        products.map((item, i) => {
                            return (
                                <Category key={item.type} itemsArray={item} index={i}/>
                            )
                        }) : ''
                }

            </main>
            <CartToast message={cartToast}/>
            <Cart opened={isCartPopupOpen} onClose={() => setCartPopupOpen(false)}/>
            <ActiveOrdersPopup opened={isOpenPopupActiveOrders} onClose={() => setIsOpenPopupActiveOrders(false)}/>
            <LoginComponent opened={isOpenLoginPopup} onClose={() => setIsOpenLoginPopup(false)}/>

            {/*{ viewCookiePopup ? <CookiePopup /> : ''}*/}
            {
                isPickStreetPopupOpen &&
                    <PickStreet opened={isPickStreetPopupOpen} onClose={() => setIsPickStreetPopupOpen(false)}/>
            }
            </>
    )
}

