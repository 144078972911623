////////eeeeeeest
////////eeeeeeest
////////eeeeeeest
import {Transition} from "react-transition-group";

import './cart.css'
// import Portal from "@/hooks/Portal";

import exitSvg from '../../public/exit.svg'
import emptyCartSVG from '../../public/emptyCart.svg'
import {useOrderStore} from "../../store/order/order";
import {useRestaurantStore} from "../../store/restaurant/restaurant";
import {useState} from "react";
import {Link} from "react-router-dom";
import axios from "axios";
import {url} from "../../config";
import {useAppStore} from "../../store/app/app";


export default function Cart({opened, onClose}) {
    const {orderData, decreaseProductToCartAPI,
        RemoveProductFromCartAPI, AddPromocodeAPI, RemovePromocodeAPI, setOrderData} = useOrderStore()
    const {restaurantData, setIsPickStreetPopupOpen} = useRestaurantStore()
    const {setCartToast, setCartToastHash} = useAppStore()

    const [promocode, setPromocode] = useState('')
    const onCloseWrapper = (event) => {
        if (event.target.classList.contains('cart-overlay')) onClose()
    }

    const getTotalSum = () => {
        if (orderData.promocode.applied && orderData.promocode.linked && orderData.order.total_sum < orderData.order.sum) {
            return <div className='cart-final-price-line'>
                <span>{orderData.order.sum}Р</span>
                {orderData.order.total_sum}р
            </div>
        }
        return <div>{orderData.order.total_sum}р</div>
    }

    console.log(orderData)
    async function AddProductToCart (id, title){

        await axios.post(`${url}/api/v1/orders/addToOrder?menu_id=${id}`).then(function (response) {
            if (response.data.status) {
                setOrderData(response.data.data)
                setCartToast(title)
                setCartToastHash()
            } else {
                if (response.data.errorCode === 206 || response.data.errorCode === 100 ) {
                    setIsPickStreetPopupOpen(true)
                } else {
                    alert(response.data.errorMessage)
                }
            }
        })


    }

    if (Object.keys(orderData).length !== 0 && orderData.order.items.length !== 0) {
        return (
            // <Portal>
            <Transition
                in={opened}
                timeout={200}
                unmountOnExit={true}
            >
                {(state) => (
                    <div className={`cart-container ${state}`}>
                        <div className='cart-overlay' onClick={onCloseWrapper}/>
                        <div className={`cart-content cart-content-${state}`}>
                            <section className='cart-title-section'>
                                <label className='cart-title'>
                                    <span className='cart-title-text'>Корзина</span>
                                    <button className='cart-exit' onClick={onClose}>
                                        <img src={exitSvg} height={25} alt={'Закрыть корзину'}/>
                                    </button>
                                </label>
                                <p className='cart-subtitle'>Минимальная сумма доставки {restaurantData.min_sum}Р</p>
                            </section>

                            <section className='cart-items'>
                                {orderData.promocode.applied && orderData.promocode.linked
                                    && orderData.promocode.type === 1 && <PromocodeItem item={orderData.promocode.effect}/>}
                                {
                                    orderData.order.items.map((item) => {
                                        return (
                                            <article className='cart-item' key={item.id}>
                                                <div className='cart-item-top-part'>
                                                    <div className='cart-item-left-part'>
                                                        <img src={item.img} alt={item.title} height={60} width={60}/>
                                                        <div className='cart-item-summary'>
                                                            <p className='cart-item-title'>{item.title}</p>
                                                            <p className='cart-item-subtitle'>{item.size}{item.unit}</p>
                                                        </div>
                                                    </div>
                                                    <button className='cart-item-delete'
                                                            onClick={() => RemoveProductFromCartAPI(item.id)}>
                                                        <img src={exitSvg} alt={"Удалить продукт из корзины"} height={15} />
                                                    </button>

                                                </div>

                                                <div className='cart-item-bottom-part'>
                                                    <span className='cart-item-price'>{item.sum}P</span>
                                                    <button className='cart-item-count-button'>
                                                        <span className='cart-item-button-minus'
                                                              onClick={() => decreaseProductToCartAPI(item.id)}>-</span>
                                                        <span className='cart-item-button-count'>{item.quantity}</span>
                                                        <span className='cart-item-button-plus'
                                                              onClick={() => {
                                                                  const infostr = `${item.title}, ${item.size} ${item.unit}`
                                                                  AddProductToCart(item.id, infostr)
                                                              }}>+</span>
                                                    </button>
                                                </div>

                                            </article>
                                        )
                                    })
                                }

                            </section>

                            <section className='predl'></section>

                            <aside className='cart-menu'>
                                <div className='cart-promocode-block' data-message={orderData.promocode.message}>
                                    <div className='cart-promocode-input-block'>
                                        <input type="text" placeholder='Промокод' className='cart-input-promocode disactive-input'
                                               value={orderData.promocode.applied ? orderData.promocode.promocode : promocode}
                                               onChange={(e) => setPromocode(e.target.value)}
                                               readOnly={orderData.promocode.promocode}
                                        />
                                        <span
                                            className={orderData.promocode.applied ? 'promocode-green-message' :
                                                'promocode-red-message'}>
                                            {orderData.promocode.message}</span>
                                    </div>

                                    <button onClick={() => {
                                        orderData.promocode.promocode !== '' ? RemovePromocodeAPI() : AddPromocodeAPI(promocode)
                                    }}
                                            className={orderData.promocode.promocode !== '' ? 'cart-promocode-button-change' : 'cart-promocode-button'}
                                    >{orderData.promocode.promocode !== '' ? 'Изменить' : 'Применить'}</button>
                                </div>

                                {/*<div className='cart-bonuses'>*/}
                                {/*    <span>Начислим бонусы</span>*/}
                                {/*    <span>95</span>*/}
                                {/*</div>*/}

                                <div className='cart-final-price'>
                                    <span>Сумма заказа</span>
                                    {getTotalSum()}
                                    {/*<div>*/}
                                    {/*    <span>{orderData.order.total_sum}Р</span>*/}
                                    {/*    /!*{orderData}*!/*/}
                                    {/*</div>*/}
                                </div>

                                {orderData.order.total_sum >= restaurantData.min_sum ?
                                    <Link to={'/checkout'} className='cart-confirm-button'>
                                        Продолжить оформление
                                    </Link> :
                                    <button className='cart-confirm-button-disactive'>
                                        До минимальной суммы добавьте продуктов еще на {restaurantData.min_sum - orderData.order.total_sum}р
                                    </button>
                                }



                            </aside>


                        </div>
                    </div>
                )
                }
            </Transition>
            // </Portal>
        )
    } else {
        return (
            <Transition
                in={opened}
                timeout={200}
                unmountOnExit={true}
            >
                {(state) => (
                    <div className={`cart-container ${state}`}>
                        <div className='cart-overlay' onClick={onCloseWrapper}/>
                        <div className={`cart-content cart-content-${state}`} onClick={() => onClose()}>
                            <div className='empty-cart-wrapper'>
                                <div className='empty-cart-back'>&#60; Назад</div>
                                <img src={emptyCartSVG} alt="" className='empty-cart-image'/>
                                <p className='empty-cart-title'>Ваша корзина пуста!</p>
                                <p className='empty-cart-subtitle'>Добавте что-нибудь и порадуйте нашего котика!</p>
                            </div>
                        </div>
                    </div>
                )
                }
            </Transition>
        )
    }
}



const PromocodeItem = ({item}) => {
    const {RemovePromocodeAPI} = useOrderStore()
    return (
        <article className='cart-item' key={item.id}>
            <div className='cart-item-top-part'>
                <div className='cart-item-left-part'>
                    <img src={item.img} alt={item.title} height={60} width={60}/>
                    <div className='cart-item-summary'>
                        <p className='cart-item-title'>{item.title}</p>
                        <p className='cart-item-subtitle'>{item.size}{item.unit}</p>
                    </div>
                </div>
                <button className='cart-item-delete'
                    onClick={() => RemovePromocodeAPI()}
                >
                    <img src={exitSvg} alt={"Удалить продукт из корзины"} height={15}/>
                </button>
            </div>

            <div className='cart-item-bottom-part'>
                <span className='cart-item-price'>{item.price}P</span>
            </div>
        </article>
    )
}