// export const url = 'http://127.0.0.1:8000'

// export const url = 'https://api.poskoreev.ru'
// export const url = 'https://testapi.poissystem.ru'
export const url = 'https://cashierapi.poissystem.ru'






export const error_message = "Произошла неизвестная ошибка.\n" +
    "Пожалуйста, сообщите нам о ней, мы исправим ее и подарим вам промокод :)\n" +
    "Телеграм: @ilyadischenko"
