import React from "react";
import {Navigate, Route, Routes} from "react-router-dom";
import Main from "./pages/Main";

import './App.css';
import {Checkout} from "./pages/checkout/Checkout";
import {Layout} from "./pages/Layout";


function App() {
  return (
      <>

          <Routes>

              <Route path="/checkout" element={<Checkout/>}/>
              <Route path="/" element={<Layout/>}>
                  <Route index element={<Main/>}/>
              </Route>
              <Route path="/*" element={<Navigate to="/" replace />}/>
          </Routes>
      </>
  );
}

export default App;
